import React from "react";
import { HomePageContainer } from "./HomePage.style";
import { Fade } from "react-awesome-reveal";

export const HomePage = () => {
  return (
    <HomePageContainer>
      <div className="bgVideo">
        <video autoPlay muted loop>
          <source src="/images/videos/homePageBgVideo.mp4" type="video/mp4" />
        </video>
      </div>
      <Fade direction="up" triggerOnce>
        {/* <div className="event">
          <div className="city">
            <div className="circle">
              <img src="/images/12.webp" alt="shape" />
            </div>
            <div className="text">
              <p>Novi sad</p>
              <p>Petrovaradin</p>
            </div>
            <div className="orange1">
              <img src="/images/11.webp" alt="shape" />
              <p className="date">MAJ 2023</p>
            </div>
            <div className="orange2">
              <img src="/images/11.webp" alt="shape" />
            </div>
            <div className="orange3">
              <img src="/images/11.webp" alt="shape" />
            </div>
          </div>
        </div> */}
        <div
          className="ns_logo"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="ns_logo" src="/images/led.png" />
          <img alt="ns_logo" src="/images/irvasi.png" style={{ width: "40%", height: "auto" }} />
        </div>
      </Fade>
      <div className="glow_wrapper">
        <img src="/images/glow.webp" alt="glow" />
      </div>
      <div className="text_express">
        <p>
          A UJUTRU SVAKO SVOJOJ KUĆI <span className="highlight">EKSPRESNO!</span>
        </p>
        <p>
          Zajednica <span className="colored">Dragi Bravo</span> nije nastala samo da bi predstavila
          nostalgiju... već je u pitanju vremeplov koji jednu čitavu generaciju okuplja na jedno
          mesto i vraća u neka <span className="colored">bolja, bezbrižnija vremena!</span>
        </p>
      </div>
      <div className="glow_wrapper">
        <img src="/images/glow.webp" alt="glow" />
      </div>
      <div className="partners">
        <div className="icon">
          <img src="/images/icons/5.webp" alt="icon" />
        </div>
        <div className="icon">
          <img src="/images/icons/6.webp" alt="icon" />
        </div>
        <div className="icon">
          <img src="/images/icons/7.webp" alt="icon" />
        </div>
        <div className="icon">
          <img src="/images/icons/8.webp" alt="icon" />
        </div>
        <div className="icon">
          <img src="/images/icons/9.webp" alt="icon" />
        </div>
        <div className="icon">
          <img src="/images/icons/pepsi.png" alt="icon" />
        </div>
      </div>
      <div className="btns">
        <div className="btn">
          <button>
            <a
              href="https://tickets.rs/tour/dragi_bravo_festival_286"
              target="_blank"
              rel="noreferrer"
            >
              KUPI KARTU
            </a>
          </button>
        </div>
      </div>
    </HomePageContainer>
  );
};
